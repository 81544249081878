import httpClient from "../httpClient"

import { formatData } from "../request"
import { encodeRequestParameters } from "../request"

import { geocodingSearchFormat } from "../formats"

/**
 * Returns a list of accessType
 */
export function getGeocodingSearch(data) {
    data = formatData(geocodingSearchFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .get("/geocoding/search", { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}
